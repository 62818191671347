<template>
  <el-dialog
    width="30%"
    title="支付方式"
    :visible.sync="isShow"
    :before-close="handleDialogCancel"
    :close-on-click-modal="false"
  >
    <el-form :model="form" :rules="rules" ref="form">
      <el-form-item label="支付平台" label-width="100px" prop="payPlatformName">
        <el-input
          v-model="form.payPlatformName"
          autocomplete="off"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="支付方式" label-width="100px" prop="payTypeName">
        <el-input
          v-model="form.payTypeName"
          autocomplete="off"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="支付图标" label-width="100px" prop="payIcon">
        <upload v-model="form.payIcon" tipType="custom" />
      </el-form-item>
      <el-form-item label="默认商户号" label-width="100px" prop="appId">
        <el-radio-group v-model="form.payeeAppType" @change="payeeAppTypeChange">
          <el-radio :label="PayeeAppType.FIXED">固定商户号收款</el-radio>
          <el-radio :label="PayeeAppType.POLLING">商户号轮询收款</el-radio>
        </el-radio-group>
        <el-select v-model="form.appId" >
          <template  v-if="form.payeeAppType === PayeeAppType.FIXED">
            <el-option
                v-for="item in merchantOptions"
                :key="item.id"
                :label="`${item.appId}(${item.payCompanyName})`"
                :value="item.appId"
            ></el-option>
          </template>
          <template  v-if="form.payeeAppType === PayeeAppType.POLLING">
            <el-option
                v-for="item in ruleFindPagOptions"
                :key="item.id"
                :label="`${item.ruleName}`"
                :value="item.id"
            ></el-option>
          </template>
        </el-select>
      </el-form-item>
      <el-form-item label="适用客户" label-width="100px" prop="customerIds">
        <el-radio-group v-model="customerType">
          <el-radio :label="CustomerType.ALL">全部客户</el-radio>
          <el-radio :label="CustomerType.ASSIGN">指定客户</el-radio>
        </el-radio-group>
        <template v-if="customerType == CustomerType.ASSIGN">
          <!-- 只展示启用客户 -->
          <el-select
            v-model="form.customerIds"
            multiple
            placeholder="请选择"
            filterable
          >
            <el-option
              v-for="item in customerList"
              :key="item.id"
              :label="item.customerName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </template>
      </el-form-item>
      <el-form-item label="适用场景" label-width="100px" prop="scenes">
        <el-radio-group v-model="sceneType">
          <el-radio :label="SceneType.ALL">全部场景</el-radio>
          <el-radio :label="SceneType.ASSIGN">指定场景</el-radio>
        </el-radio-group>
        <template v-if="sceneType == SceneType.ASSIGN">
          <!-- 只展示启用场景 -->
          <el-select
            v-model="form.scenes"
            multiple
            placeholder="请选择"
            filterable
          >
            <el-option
              v-for="item in sceneList"
              :key="item.id"
              :label="item.sceneName"
              :value="item.scene"
            >
            </el-option>
          </el-select>
        </template>
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="handleDialogCancel">取 消</el-button>
      <el-button type="primary" :loading="loading" @click="handleDialogConfirm('form')"
        >确 定</el-button
      >
    </div>
  </el-dialog>
</template>
<script>
import {CustomerType, PayeeAppType, SceneType} from '@/constants/pay';
import payCenterApi from '@/api/paycenter';
import Upload from '@/components/Upload';
export default {
  components: {
    Upload,
  },
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
    changeStatus: {
      type: Function,
      default: null,
    },
    currentPaymentItemData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      CustomerType,
      PayeeAppType,
      SceneType,
      customerType: CustomerType.ALL,
      sceneType: SceneType.ALL,
      form: {},
      initMerchantOptions: [],
      merchantOptions: [],
      customerList: [],
      sceneList: [],
      customerIdsOfDisable: [],
      scenesOfDisable: [],
      initRuleFindPagOptions: [],
      ruleFindPagOptions: [],
      rules: {
        appId: [
          {
            required: true,
            trigger: 'change',
            message: '请选择默认商户号',
          },
        ],
        customerIds: [
          {
            required: false,
            trigger: 'change',
            message: '请选择指定客户',
          },
        ],
        scenes: [
          {
            required: false,
            trigger: 'change',
            message: '请选择指定场景',
          },
        ],
        payIcon: [
          {
            required: true,
            trigger: 'change',
            message: '请上传支付图标',
          },
        ],
      },
    };
  },
  created() {
    // 请求客户列表和场景列表（是在页面初始化时）
    this.getCustomerList();
    this.getSceneList();
    this.getDefaultMerchant();
    this.getRuleFindPageData()
  },
  methods: {
    async getCustomerList() {
      try {
        const { data } = await payCenterApi.getCustomerList({
          currentPage: 1,
          pageSize: 100000,
          customerStatus: 'NORMAL',
        });
        this.customerList = data.records;
      } catch (err) {
        console.log(err);
      }
    },
    async getSceneList() {
      try {
        const { data } = await payCenterApi.getSceneList({
          disable: false,
        });
        this.sceneList = data;
      } catch (err) {
        console.log(err);
      }
    },
    async getDefaultMerchant() {
      try {
        const { data } = await payCenterApi.findPayAccountDefaultPages({
          currentPage: 1,
          pageSize: 1000000,
        });
        this.initMerchantOptions = data?.records || [];
        this.merchantOptions = { ...this.initMerchantOptions };
      } catch (err) {
        console.log(err);
      }
    },
    async getRuleFindPageData() {
      try {
        const { data } = await payCenterApi.getRuleFindPage({
          currentPage: 1,
          pageSize: 1000000,
          customerId: 'defaultId'
        });
        this.initRuleFindPagOptions = data?.records || [];
        this.ruleFindPagOptions =  [...this.initRuleFindPagOptions ];
      } catch (err) {
        console.log(err);
      }
    },
    handleDialogConfirm(formName) {
      this.customerType == CustomerType.ALL &&
        (this.rules.customerIds[0].required = false);
      this.sceneType == SceneType.ALL &&
        (this.rules.scenes[0].required = false);
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.updatePayPlatformDefault();
        }
      });
    },

    async updatePayPlatformDefault() {
      try {
        this.loading = true;
        const { data } = await payCenterApi.updatePayPlatformDefault({
          ...this.form,
          scenes:
            this.sceneType === SceneType.ALL
              ? '[]'
              : JSON.stringify(this.form.scenes.concat(this.scenesOfDisable)),
          customerIds:
            this.customerType === CustomerType.ALL
              ? '[]'
              : JSON.stringify(
                  this.form.customerIds.concat(this.customerIdsOfDisable),
                ),
        });
        this.loading = false;
        !data && this.$message.error('保存失败');
        this.changeStatus(false, 'confirm');
      } catch (err) {
        this.loading = false;
        console.log(err);
      }
    },
    handleDialogCancel() {
      this.changeStatus(false, 'cancel');
    },
    filterDisable(data, value, name) {
      if (name === 'customerIds') {
        data = data.map(item => item.id);
      } else {
        data = data.map(item => item.scene);
      }

      // 查到：在customerList不存在但customerIds中存在的
      // selectedAlsoDisableCustomerIds要在保存的时候和customerIds合并一起传给后端
      this[name + 'OfDisable'] = value.filter(
        item => data.includes(item) === false,
      );
      return value.filter(item => data.includes(item));
    },
    handleDisabledData() {
      // 回显时处理掉已选择但是被禁用的客户和场景，并且已选但被禁用的数据，保存时要传递给接口
      const customerIds = JSON.parse(this.form.customerIds),
        scenes = JSON.parse(this.form.scenes);
      this.customerType =
        customerIds.length > 0 ? CustomerType.ASSIGN : CustomerType.ALL;
      this.sceneType = scenes.length > 0 ? SceneType.ASSIGN : SceneType.ALL;
      this.form.customerIds = this.filterDisable(
        this.customerList,
        customerIds,
        'customerIds',
      );
      this.form.scenes = this.filterDisable(this.sceneList, scenes, 'scenes');
    },
    payeeAppTypeChange() {
      this.form.appId = ''
      setTimeout(() => {
        this.$refs.form?.clearValidate('appId')
      }, 0);
    },
  },
  watch: {
    customerType(value) {
      if (value == CustomerType.ALL) {
        this.form.customerIds = [];
        this.rules.customerIds[0].required = false;
      } else {
        this.rules.customerIds[0].required = true;
        setTimeout(() => {
          this.$refs.form?.clearValidate('customerIds');
        }, 30);
      }
    },
    sceneType(value) {
      if (value == SceneType.ALL) {
        this.form.scenes = [];
        this.rules.scenes[0].required = false;
      } else {
        this.rules.scenes[0].required = true;
        setTimeout(() => {
          this.$refs.form?.clearValidate('scenes');
        }, 30);
      }
    },
    isShow(value) {
      if (value) {
        this.form = { ...this.currentPaymentItemData };
        this.merchantOptions = this.initMerchantOptions.filter(
          item => item.payPlatform === this.form.payPlatform,
        );
        this.ruleFindPagOptions = this.initRuleFindPagOptions.filter(
            item => item.payPlatform === this.form.payPlatform,
        )
        this.handleDisabledData();
        setTimeout(() => {
          this.$refs.form?.clearValidate();
        }, 10);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.el-input,
.el-select {
  width: 100%;
}
</style>
