<template>
  <div
    :class="
      (limitNum < 2 && (value || '').length > 0 && 'main') ||
        (disabled && 'main')
    "
  >
    <el-upload
      :limit="limitNum"
      :headers="headers"
      :action="getUploadUrl"
      :accept="accept"
      list-type="picture-card"
      :file-list="fileList"
      :before-upload="handleBeforeUpload"
      :on-success="handleSuccess"
      :on-exceed="handleExceed"
      :disabled="disabled"
      :multiple="!isSingle"
    >
      <i class="el-icon-plus avatar-uploader-icon" />
      <div slot="tip" v-if="!disabled">
        <div v-if="tipType === 'custom'">
          图片大小不得超过{{ getMaxSize
          }}<span v-show="limitNum >= 10000 ? false : true"
            >，最多上传{{ limitNum }}张，</span
          >
          <span>支持: PNG,JPEG,JPG</span>
        </div>
        <div v-show="limitNum >= 10000 ? false : true" v-else>
          图片大小不得超过{{ getMaxSize
          }}<span>，最多上传{{ limitNum }}张</span>
        </div>
      </div>
      <div slot="file" slot-scope="{ file }">
        <img class="el-upload-list__item-thumbnail" :src="file.url" />
        <span class="el-upload-list__item-actions">
          <span
            class="el-upload-list__item-preview"
            @click="handlePictureCardPreview(file)"
          >
            <i class="el-icon-zoom-in" />
          </span>
          <span
            v-if="!disabled"
            class="el-upload-list__item-delete"
            @click="handleRemove(file)"
          >
            <i class="el-icon-delete" />
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" />
    </el-dialog>
  </div>
</template>

<script>
import { divide } from 'number-precision';
import storage from 'store';
import { ACCESS_TOKEN } from '@/constants/storage';
export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    limit: {
      type: Number,
      default: 15,
    },
    size: {
      type: Number,
      default: 1024 * 1024,
    },
    accept: {
      type: String,
      default: '',
    },
    tipType: {
      type: String,
      default: 'common',
    },
    value: [Array, String],
    disabled: {
      type: Boolean,
      default: false,
    },
    isSingle: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(val) {
      //if (!this.isClickUpload) {
      this.setDefaultValue(val);
      //}
    },
  },
  computed: {
    getUploadUrl() {
      return '/api/filecenter/upload';
    },
    getMaxSize() {
      if (this.maxSize < 1024 * 1024) {
        return divide(this.maxSize, 1024) + 'KB';
      }
      return divide(this.maxSize, 1024 * 1024) + 'M';
    },
  },
  created() {
    this.setDefaultValue(this.value);
  },
  data() {
    return {
      maxSize: this.size,
      imgHost: 'https://ka-img-dev.billbear.cn',
      dialogImageUrl: '',
      dialogVisible: false,
      isClickUpload: false,
      limitNum: this.limit,
      fileList: [],
      headers: {
        Authorization: 'Bearer ' + storage.get(ACCESS_TOKEN),
      },
    };
  },
  methods: {
    setDefaultValue(val) {
      if (typeof val !== 'object') {
        this.limitNum = 1;
        const arrlength = val?.split('https').length;
        if (val) {
          if (arrlength === 2) {
            this.fileList = [
              {
                response: { fileKey: val },
                url: val,
              },
            ];
          } else {
            this.fileList = [
              {
                response: { fileKey: val },
                url: val.includes('.com', 'http:/', 'https:/')
                  ? val
                  : this.imgHost + '/' + val,
              },
            ];
          }
        } else {
          this.fileList = [];
        }
        return;
      }

      if (val.length === 0) {
        this.fileList = [];
        return;
      }

      this.fileList = val.map(key => ({
        response: { fileKey: key },
        url: key.includes('.com', 'http:/', 'https:/')
          ? key
          : this.imgHost + '/' + key,
      }));
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    handleRemove(file) {
      if (typeof this.value === 'string') {
        this.fileList = [];
        this.dispatch();
        return;
      }
      this.isClickUpload = true;
      const index = this.fileList.findIndex(item => item.uid === file.uid);
      if (index >= 0) {
        this.fileList.splice(index, 1);
        this.dispatch();
      }
    },
    handleBeforeUpload(file) {
      if (file.size > this.size) {
        this.$message.error('图片大小不能超过 ' + this.getMaxSize);
        return false;
      }
      if (
        this.tipType === 'custom' &&
        file.type !== 'image/jpeg' &&
        file.type !== 'image/png' &&
        file.type !== 'image/jpg'
      ) {
        this.$message.error('上传头像图片只能是JPG、PNG、JPEG格式!');
        return false;
      }
    },
    handleExceed() {
      this.$message.error('图片上传数量不能超过 ' + this.limitNum + '张');
    },
    handleSuccess(response, file, fileList) {
      this.isClickUpload = true;
      this.fileList = fileList;
      this.dispatch();
    },
    dispatch() {
      const filelist = this.fileList.map(item => item.response.fileKey);
      const result = this.limitNum === 1 ? filelist?.[0] || '' : filelist;
      console.log(result);
      this.$emit('change', result);
      this.$parent.$emit('el.form.change', result);
    },
  },
};
</script>
<style lang="less">
.main {
  .el-upload--picture-card {
    display: none;
  }
}
</style>
